import styled from '@emotion/styled'

import CircularLabelButton from '@/components/CircularLabelButton'

import WysiwygBase from '@/components/Wysiwyg'

import { mediaMax, sectionSubtitle } from '@/styles/mixins'
import LocalizedGatsbyLink from '@/components/LocalizedGatsbyLink'

export const Container = styled.div`
  position:relative;
  width: 80%;
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const SectionOne = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  align-items: center;
  /* width: 65%;
  ${mediaMax.md} {
    width: 80%;
  }
  ${mediaMax.xs} {
    width: 95%;
  } */
`

export const CircularBtnWrapper = styled.div`
  margin-top: 6rem;
  display: flex;
  justify-content: center;
  position: ${({ position }) => position === 'initial' ? 'absolute' : 'fixed'};
  top: ${({ position, offsetTop }) => position === 'initial' ? 'auto' : '100px'};
  transform: translateY(${({ position, offsetTop }) => position === 'bottom' ? `-${offsetTop}px` : 0});
  margin-left: 3rem;
  left: ${({ position, offsetLeft }) => position === 'initial' ? 0 : `${offsetLeft}px`};
  z-index: 5;
  ${mediaMax.md} {
    visibility: hidden;
    width: 0;
    height: 0;
    margin: 0;
  }
`

export const CircularButton = styled(CircularLabelButton)`
  transform: scale(1.2);
`

export const ShareButton = styled.a`
  ${sectionSubtitle()}
  position: absolute;
  right: 0;
  background: none;
  border: none;
  outline: none;
  height: min-content;
  display: flex;
  align-items: center;
  justify-content:center;
  gap: 1.2rem;
  margin-top: ${({ theme }) => theme.spacing.medium};
  text-decoration: none;
  cursor: pointer;
  ${mediaMax.lg} {
    flex-direction:row-reverse;
  }
  ${mediaMax.md} {
    visibility: hidden;
    width: 0;
    height: 0;
  }
  &.small {
    margin-top: 0;
    ${mediaMax.md} {
      visibility: visible;
      width: auto;
      height: auto;
    }
    ${mediaMax.xs}{
      position: inherit;
    }
  }
`

export const ShareText = styled.p`
  ${sectionSubtitle()}
  ${mediaMax.md} {
    color: ${({ theme }) => theme.colors.brown};
  }
  ${mediaMax.xs} {
    font-size: ${({ theme }) => theme.fontSizes.paragraph.xs};
  }
`

export const BackShareNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  visibility: hidden;
  width: 0;
  height: 0;
  ${mediaMax.md} {
    visibility: visible;
    width: 90%;
    height: auto;
    margin-bottom: ${({ theme }) => theme.spacing.medium};
  }
  ${mediaMax.xs}{
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
  }
`

export const BackButton = styled(LocalizedGatsbyLink)`
  ${sectionSubtitle()}
  display: flex;
  align-items:center;
  gap: 1.2rem;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  ${mediaMax.md} {
    color: ${({ theme }) => theme.colors.brown};
  }
  ${mediaMax.xs} {
    font-size: ${({ theme }) => theme.fontSizes.paragraph.xs};
  }
`

export const TitleContainer = styled.div`
  width: 65%;
  ${mediaMax.md} {
    margin-top: ${({ theme }) => theme.spacing.medium};
    width: 80%;
  }

  ${mediaMax.xs} {
    width: 95%;
    margin-top: ${({ theme }) => theme.spacing.xsmall};
  }

`

export const Tags = styled.div`
    ${sectionSubtitle()}
    margin-bottom:${({ theme }) => theme.spacing.xsmall};
`

export const Title = styled.h1`
  font-size: 3.5rem;
  font-family: ${({ theme }) => theme.fonts.serif};
  color: ${({ theme }) => theme.colors.brown};
  font-weight: bold;
  margin-bottom:${({ theme }) => theme.spacing.small};
  ${mediaMax.xs} {
    word-wrap: break-word;
  }
`

export const Introduction = styled(WysiwygBase)`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.green};
  line-height: 1.875rem;
  margin-bottom: ${({ theme }) => theme.spacing.small};
  width: 60%;
  &.uppercase{
    line-height: 1.625rem;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
    ${mediaMax.xs} {
      line-height: 3.0rem;
    }
    ${mediaMax.md} {
      margin-bottom: ${({ theme }) => theme.spacing.small};
    }
  }

  ${mediaMax.md} {
    width: 80%;
  }

  ${mediaMax.xs} {
    width: 95%;
  }
`
