import * as React from 'react'
import PropTypes from 'prop-types'
import Button from '@/components/Button'
import isExternalLink from '@/utils/isExternalLink'
import resolveAssetURL from '@/utils/resolveAssetURL'

import {
  Container, Wrapper, Image, Subtitle, Title, Wysiwyg
} from './style'

const CardWithImage = ({
  src,
  alt,
  subtitle,
  title,
  content,
  direction = 'image-text',
  to,
  label
}) => {
  return (
    <Wrapper className={ direction }>
      <Container>
        {/* {content} */}
        <Subtitle>{subtitle}</Subtitle>
        <Title>{ title }</Title>
        <Wysiwyg>{ content }</Wysiwyg>
        {label && (
          <Button
            className='productCardRetailersBtn'
            to={ to }
            ctaTracking={ false }
            target={ isExternalLink(to) ? '_blank' : '_self' }
          >
            { label }
          </Button>
        )}
      </Container>
      <Container>
        <Image loading='lazy' src={ resolveAssetURL(src) } alt={ alt } />
      </Container>
    </Wrapper>
  )
}

CardWithImage.propTypes = {
  content: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  direction: PropTypes.string,
  to: PropTypes.string,
  label: PropTypes.string
}

export default CardWithImage
