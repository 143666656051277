
import styled from '@emotion/styled'

import { mediaMax, sectionSubtitle, sectionTitle } from '@/styles/mixins'

import WysiwygBase from '@/components/Wysiwyg'

import { ButtonWrapper as Button } from '@/components/Button/style'


export const Container = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${Button}{
      max-width: 240px;
      margin-top: 24px;
  }

  ${mediaMax.xs}{
      width: 100%;
  }

`

export const Wrapper = styled.div`
  position:relative;
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin-top: 7rem;
  margin-bottom: 7rem;
  
  ${mediaMax.xs} {
    width: 90%;
    flex-direction: column-reverse;
  }

  &.image-text{
      flex-direction: row-reverse;
      gap: 5rem;

      ${mediaMax.xs}{
          flex-direction: column-reverse;
      }
  }
`
export const Image = styled.img`
    width: 100%;
`

export const Subtitle = styled.h3`
    ${sectionSubtitle()}
`
export const Title = styled.h3`
  ${sectionTitle()}
  width: 100%;
  color: ${({ theme }) => theme.colors.brown};
  margin-bottom: 4rem;
  margin-top: 2rem;
`

export const Wysiwyg = styled(WysiwygBase)`
  p {  
    color: ${({ theme }) => theme.colors.grey};
    ${mediaMax.xs} {
      width: 100%;
    }
  }
`