import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import Icon from '@/components/Icon'
import theme from '@/theme'
import { useScrollPosition } from '@/hook'
import isExternalLink from '@/utils/isExternalLink'

import {
  Container,
  CircularButton,
  TitleContainer,
  Tags,
  Title,
  ShareButton,
  ShareText,
  SectionOne,
  Introduction,
  BackShareNav,
  BackButton,
  CircularBtnWrapper
} from './style'

const ArticleTitle = ({
  infos = '',
  title = '',
  shareURL = '',
  hideBackButton = false,
  cta,
  ctaLabel,
  introduction = '',
  event = false
}) => {
  const scrollTop = useScrollPosition()
  const containerRef = useRef(null)
  const [backButtonPosition, setBackButtonPosition] = useState('initial')
  const [fixedBackButtonLeftOffset, setFixedBackButtonLeftOffset] = useState(0)
  const [fixedBackButtonTopOffset, setFixedBackButtonTopOffset] = useState(0)
  
  useEffect(() => {
    if (!hideBackButton) {
      const bottomPos = containerRef.current?.parentNode.offsetTop + containerRef.current?.parentNode.clientHeight - window.innerHeight - 300

      if (scrollTop >= containerRef.current?.offsetTop - 100 && scrollTop < bottomPos) {
        setBackButtonPosition('fixed')
        setFixedBackButtonLeftOffset(containerRef.current?.offsetLeft)
      } else if (scrollTop >= bottomPos) {
        setBackButtonPosition('bottom')
        setFixedBackButtonLeftOffset(containerRef.current?.offsetLeft)
        setFixedBackButtonTopOffset(scrollTop - bottomPos)
      } else {
        setBackButtonPosition('initial')
      }
    }
  }, [scrollTop])

  const articleTag = infos.split(' ').pop()

  const [articleTagShare, setArticleTagShare] = useState('article')

  useEffect(() => {
    if (articleTag === 'Recipes') {
      setArticleTagShare('recipe')
    }
  }, [articleTag])

  return (
    <Container ref={ containerRef }>
      <BackShareNav>
        <BackButton to='/journal'>
          <Icon
            color={ theme.colors.brown }
            name='arrow-left'
            size={ 32 }
          />
          Back
        </BackButton>
        {shareURL && (
          <ShareButton className='small' href={ `mailto:?subject=${title}&body=${shareURL}` }>
            <Icon
              color={ theme.colors.brown }
              name='share'
            />
            <ShareText>
              {/* Share this {articleTag === 'Recipes' ? 'recipe' : 'article'} */}
              Share this {event ? 'Event' : articleTagShare}
            </ShareText>
          </ShareButton>)}
      </BackShareNav>
      <SectionOne>
        {!hideBackButton && (
          <CircularBtnWrapper
            position={ backButtonPosition }
            offsetLeft={ fixedBackButtonLeftOffset }
            offsetTop={ fixedBackButtonTopOffset }
          >
            <CircularButton
              id="textcercle"
              icon='arrow-left'
              label={ ctaLabel }
              color='blue'
              nbLabels={ 9 }
              to={ cta }
              target={ isExternalLink(cta) ? '_blank' : '_self' }
            />
          </CircularBtnWrapper>
        )}
        <TitleContainer>
          {infos &&
            <Tags>
              { infos }
            </Tags>}
          {title &&
            <Title>
              { title }
            </Title>}
        </TitleContainer>
        {introduction &&
          <Introduction>
            { introduction }
          </Introduction>}
        {shareURL && (
          <ShareButton href={ `mailto:?subject=${title}&body=${shareURL}` }>
            <Icon
              color={ theme.colors.green }
              name='share'
            />
            <ShareText>
              {/* Share this {articleTag === 'Recipes' ? 'recipe' : 'article'} */}
              Share this {event ? 'Event' : articleTagShare}
            </ShareText>
          </ShareButton>)}
      </SectionOne>
    </Container>
  )
}

ArticleTitle.propTypes = {
  infos: PropTypes.string,
  title: PropTypes.string,
  cta: PropTypes.string,
  ctaLabel: PropTypes.string,
  introduction: PropTypes.string,
  hideBackButton: PropTypes.bool
}

export default ArticleTitle
