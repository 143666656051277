import styled from '@emotion/styled'
import {mediaMax, sectionTitle} from '../../mixins'
import { Wrapper as CardWithImgWrapper } from '@/components/CardWithImage/style'
import { Container as CardWithImgContainer} from '@/components/CardWithImage/style'
import WysiwygBase from '@/components/Wysiwyg'

// export const ArticleTwoColumns = styled.div`
//     width: 80%;
//     margin: 0 auto;
//     justify-content: space-between;
//     display: flex;
//     flex-direction: row;
//     flex-wrap: wrap;
//     gap: 2rem;

//   ${mediaMax.sm}{
//     flex-direction: column;
//   }
//   ${mediaMax.xs} {
//     // padding: 0 5vw;
//   }
// `
// export const ArticleTwoColumnsContainer = styled.div`
//     width: 45%;
//     min-width: 320px;
//   ${mediaMax.xs} {
//     // padding: 0 5vw;
//   }

//   ${mediaMax.sm}{
//     width: 100%;
//   }
//   > div[loading='lazy']{
//       width: 100% !important;
//   }
// `


export const NewsLetterWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 5rem;
  gap: 5rem;
`

export const NewsLetterWrapperTitle = styled.h1`
  ${sectionTitle()};
  max-width: 80rem;
  text-align: left;
  width: 100%;
  ${mediaMax.xs} {
    padding: 0 5vw;
  }
`

export const Wysiwyg = styled(WysiwygBase)`
  max-width: 80rem;
  p {
    color: ${({ theme }) => theme.colors.grey};
    ${mediaMax.xs} {
      width: 100%;
    }
  }
`

export const Wrapper = styled.div`
  width: 80%;

  ${mediaMax.xs}{
    width: 100%;
  }
  ${CardWithImgWrapper}{
    flex-direction: column-reverse !important;
  }

  &.one-column{
    ${CardWithImgWrapper}{
      width: 100%;
    }

    ${CardWithImgContainer}{
      width: 100%;
      // margin: 20px;
      margin: 0 auto;
      ${mediaMax.xs}{
        width: 90%;
      }
    }
  }

  &.two-column{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 3.5rem;

    ${CardWithImgContainer}{
      width: 100%;
    }

    ${CardWithImgWrapper}{
      justify-content: column-reverse !important;
    }

    ${mediaMax.xs}{
      flex-direction: column;
    }
  }

  &.three-column{
    display: flex;
    justify-content: space-between;
    gap: 2rem;

    ${CardWithImgWrapper}{
      justify-content: flex-end !important;
      /* justify-content: column-reverse; */
    }
    ${CardWithImgContainer}{
      width: 100%;
    }
    ${mediaMax.xs}{
      flex-direction: column;
      ${CardWithImgWrapper}{
        justify-content: center;
        margin-top: 3rem;
        margin-bottom: 3rem;
      }
    }
  }
`
